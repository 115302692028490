// firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB-MiDdIXQDRJzyM4xo6j7ckh_L5VALYsg",
  authDomain: "optimaweb-4c6f3.firebaseapp.com",
  projectId: "optimaweb-4c6f3",
  storageBucket: "optimaweb-4c6f3.appspot.com",
  messagingSenderId: "122783401116",
  appId: "1:122783401116:web:20e97a1aaeb27b58279959",
  measurementId: "G-QHM7QKVC8Q"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export default app;
