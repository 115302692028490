import React, { useState, useEffect } from 'react';
import '../styles/Industries.css';


function Industries() {

  const [hideNav, setHideNav] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop > lastScrollTop) {
        setHideNav(true); // Scrolling down
      } else {
        setHideNav(false); // Scrolling up
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.location.href = '/'; // Navigate to homepage if sectionId is not found
    }
  };

  return (
    <div id='industriesBody'>
      <div className="IcardMainContainer">
        <div>
          <h1 className='IcardTitle appear'>
              View Our<br/>
              <span className='IcardTitleOrange appear'>Specialties</span>
          </h1>
        </div>
        <div className='IcardContainer'>
          <div className='Icard'>
            <p className='IcardText appear'>
              Web Design
            </p>
            <p className='IcardParagraph appear'>
              Our web design services focus on creating
               visually appealing and user-friendly websites
                that effectively communicate your brand's
                 message. We work closely with you to understand
                  your vision and bring it to life with stunning
                   design elements and seamless navigation.
            </p>
          </div>
          <div className='Icard IcardMiddle'>
            <p className='IcardText appear'>
              Web Development
            </p>
            <p className='IcardParagraph appear'>
              Our web development team specializes in building
               robust and scalable websites that are optimized for
                performance. Whether you need a simple brochure
                 site or a complex web application, we have the
                  expertise to deliver a solution that meets your business needs.
            </p>
          </div>
          <div className='Icard'>
            <p className='IcardText appear'>
              Marketing - SEO
            </p>
            <p className='IcardParagraph appear'>
              Our marketing and SEO services help you
               increase your online visibility and attract
                more visitors to your website. We use
                 the latest strategies and techniques to
                  improve your search engine rankings and drive
                   targeted traffic to your site, ensuring
                    that you reach your target audience effectively.
            </p>
          </div>
        </div>
        <a className='IcardButton appear' href="/" onClick={(e) => scrollToSection(e, 'ProcessBody')}>
            <button>
                View Our Process
            </button>
        </a>
      </div>
    </div>
  );
}

export default Industries;