import React from 'react';
import '../styles/TAC.css';
import Contact from '../pages/Contact';


function TAC() {
  return (
    <div>
    <div id='TACBody'>
      <div className='TACContainer'>
        <div className='TACHeader'>
          <h1>Terms and Conditions</h1>
        </div>
        <div className='TACContent'>
          <div className='TACSection'>
            <h1>Introduction</h1>
            <p>
              Welcome to our website. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully.
            </p>
          </div>
          <div className='TACSection'>
            <h1>Acceptance of Terms</h1>
            <p>
              By accessing this website, you accept these terms and conditions in full. If you disagree with any part of these terms and conditions, you must not use this website.
            </p>
          </div>
          <div className='TACSection'>
            <h1>Refund Policy</h1>
            <p>
              Our refund policy is valid within the first week of service. If any issues are noticed, we will work to fix them; however, no actual refunds will be made. At the start, a 25% down payment is required before we begin creating the website. If you decide to cancel after this payment, you can receive half of the down payment back, but not the full amount. Once the website is fully delivered and all verification steps are completed, and payment is made in full, no refunds will be issued. Free edits are available for the first week, after which edit prices will be determined based on difficulty.
            </p>
          </div>
          <div className='TACSection'>
            <h1>Use of the Website</h1>
            <p>
              You may use our website for lawful purposes only. You must not use our website in any way that breaches any applicable local, national, or international law or regulation.
            </p>
          </div>
          <div className='TACSection'>
            <h1>Intellectual Property</h1>
            <p>
              The content on this website, including text, graphics, logos, and images, is the property of our company and is protected by copyright and other intellectual property laws. You may not reproduce, duplicate, copy, sell, resell, or exploit any portion of the website without express written permission from us.
            </p>
          </div>
          <div className='TACSection'>
            <h1>Limitation of Liability</h1>
            <p>
              Our company shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our website or services, even if we have been advised of the possibility of such damages.
            </p>
          </div>
          <div className='TACSection'>
            <h1>Changes to Terms</h1>
            <p>
              We reserve the right to change these terms and conditions at any time. Any changes will be posted on this page, and your continued use of the website after such changes have been posted constitutes your acceptance of the new terms and conditions.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Contact />
    </div>
  );
}

export default TAC;
