import React from 'react';
import '../styles/PrivacyPolicy.css';
import Contact from '../pages/Contact';


function PrivacyPolicy() {
  return (
    <div>
    <div id='PrivacyPolicyBody'>
      <div className='PrivacyPolicyContainer'>
        <div className='PrivacyPolicyHeader'>
          <h1>Privacy Policy</h1>
        </div>
        <div className='PrivacyPolicyContent'>
          <div className='PrivacySection'>
            <h1>Introduction</h1>
            <p>
              We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you visit our website.
            </p>
          </div>
          <div className='PrivacySection'>
            <h1>Data Collection</h1>
            <p>
              We collect personal information that you voluntarily provide to us when you register on the website, make a purchase, or contact us for support. This may include your name, email address, phone number, and payment information.
            </p>
          </div>
          <div className='PrivacySection'>
            <h1>Data Usage</h1>
            <p>
              The information we collect is used to provide and improve our services. We may use your information to process transactions, send confirmation emails, respond to inquiries, and provide customer support.
            </p>
          </div>
          <div className='PrivacySection'>
            <h1>Data Protection</h1>
            <p>
              We employ a variety of security measures to ensure the safety of your personal information. All transactions are processed through a secure server and sensitive information is transmitted via Secure Socket Layer (SSL) technology.
            </p>
          </div>
          <div className='PrivacySection'>
            <h1>Third-Party Disclosure</h1>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
            </p>
          </div>
          <div className='PrivacySection'>
            <h1>Cookies</h1>
            <p>
              We use cookies to enhance your experience on our site. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information.
            </p>
          </div>
          <div className='PrivacySection'>
            <h1>Changes to Our Privacy Policy</h1>
            <p>
              We may update our Privacy Policy from time to time. Any changes will be posted on this page, and you will be notified via email if significant changes are made.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Contact />
    </div>
  );
}

export default PrivacyPolicy;
