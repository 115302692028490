import React, { useState } from 'react';
import '../styles/Contact.css'; // Import CSS file

function Contact() {
  const [alertMessage, setAlertMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    
    try {
      const response = await fetch(process.env.REACT_APP_FORMSPREE_ENDPOINT, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        form.reset();
        setAlertMessage('Thank you for contacting us!');
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setAlertMessage('There was a problem with your submission. Please try again.');
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
  };

  return (
    <div>
      {alertMessage && (
        <div className="contactAlert">
          <span>{alertMessage}</span>
          <button className="contactcloseButton" onClick={handleCloseAlert}>&times;</button>
        </div>
      )}
      <div id='contactBody'> {/* Apply classes from Contact.css directly */}
        <div className='contactTitle' id='scrollContact'>
          <h1>
            Keep Growing
          </h1>
        </div>
        <div className='leftrightContactContainer'>
          <div className='leftSideContactContainer'>
            <div className='leftContactText'>
              <p>
                Reach Out to Us
              </p>
            </div>
            <div className='contactLinksContainer'>
              <div className='contactEmail'>
                <p>
                optimawebcreations@gmail.com
                </p>
              </div>
              <div className='contactEmail'>
                <p>
                  (470) 809-6508
                </p>
              </div>
              <div className='contactLinks'>
                <div className='contactLink'>
                  <a href='https://x.com/OptimaWebCreate'>
                    X
                  </a>
                </div>
                <div className='contactLink'>
                  <a href='https://www.facebook.com/profile.php?id=61561566501986'>
                    Facebook
                  </a>
                </div>
                <div className='contactLink'>
                  <a href='/FAQ'>
                    FAQ
                  </a>
                </div>
                <div className='contactLink'>
                  <a href='/TAC'>
                    Terms & Conditions
                  </a>
                </div>
                <div className='contactLink'>
                  <a href='/privacyPolicy'>
                    Privacy Policy
                  </a>
                </div>
                <div className='contactLink'>
                  <a href='/refundPolicy'>
                    Refund Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='rightSideContactContainer'>
            <div className='rightSideContactText'>
              <p>
                Contact Us
              </p>
            </div>
            <div className='contactUsForm'>
              <form onSubmit={handleSubmit} action={process.env.REACT_APP_FORMSPREE_ENDPOINT} method="POST" className='contactFormMain'>
                <div className='contactInput'>
                  <p>
                    First Name *
                  </p>
                  <input type="text" name="First Name" required />
                </div>
                <div className='contactInput'>
                  <p>
                    Last Name *
                  </p>
                  <input type="text" name="Last Name" required />
                </div>
                <div className='contactInput'>
                  <p>
                    Email *
                  </p>
                  <input type="email" name="Email" required />
                </div>
                <div className='contactInput'>
                  <p>
                    Company
                  </p>
                  <input type="text" name="Company" />
                </div>
                <div className='contactInputText'>
                  <p>
                    Write a message
                  </p>
                  <textarea rows="4" name="Message"></textarea>
                </div>
                <div className='contactFormSubmitButton'>
                  <button type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <footer className='contactFooterContainer'>
          <div className='contactFooter'>
            <p>
              Copyright © 2024 OptimaWeb Creations. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
      <div className='mobileContactFormContainer' id='scrollMobileContact'>
      {alertMessage && (
        <div className="McontactAlert">
          <span>{alertMessage}</span>
          <button className="McontactcloseButton" onClick={handleCloseAlert}>&times;</button>
        </div>
      )}
      <div className='contactUsFormMobile' id='scrollMobileContact'>
        <div className='rightSideContactTextMobile' id='scrollMobileContact'>
                <p>
                  Contact Us
                </p>
              </div>
              <form onSubmit={handleSubmit} action={process.env.REACT_APP_FORMSPREE_ENDPOINT} method="POST" className='contactFormMainMobile'>
                <div className='contactInputMobile'>
                  <p>
                    First Name *
                  </p>
                  <input type="text" name="First Name" required />
                </div>
                <div className='contactInputMobile'>
                  <p>
                    Last Name *
                  </p>
                  <input type="text" name="Last Name" required />
                </div>
                <div className='contactInputMobile'>
                  <p>
                    Email *
                  </p>
                  <input type="email" name="Email" required />
                </div>
                <div className='contactInputMobile'>
                  <p>
                    Company
                  </p>
                  <input type="text" name="Company" />
                </div>
                <div className='contactInputTextMobile'>
                  <p>
                    Write a message
                  </p>
                  <textarea rows="4" name="Message"></textarea>
                </div>
                <div className='contactFormSubmitButtonMobile'>
                  <button type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className='leftSideContactContainerMobile'>
            <div className='leftContactTextMobile'>
              <p>
                Reach Out to Us
              </p>
            </div>
            <div className='contactLinksContainerMobile'>
              <div className='contactEmailMobile'>
                <p>
                optimawebcreations@gmail.com
                </p>
              </div>
              <div className='contactEmailMobile'>
                <p>
                  (470) 809-6508
                </p>
              </div>
              <div className='contactLinksMobile'>
                <div className='contactLinkMobile'>
                  <a href='https://x.com/OptimaWebCreate'>
                    X
                  </a>
                </div>
                <div className='contactLinkMobile'>
                  <a href='https://www.facebook.com/profile.php?id=61561566501986'>
                    Facebook
                  </a>
                </div>
                <div className='contactLinkMobile'>
                  <a href='/FAQ'>
                    FAQ
                  </a>
                </div>
                <div className='contactLinkMobile'>
                  <a href='/TAC'>
                    Terms & Conditions
                  </a>
                </div>
                <div className='contactLinkMobile'>
                  <a href='/PrivacyPolicy'>
                    Privacy Policy
                  </a>
                </div>
                <div className='contactLinkMobile'>
                  <a href='/RefundPolicy'>
                    Refund Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
      <footer className='contactFooterContainerMobile'>
          <div className='contactFooterMobile'>
            <p>
              Copyright © 2024 OptimaWeb Creations. All rights reserved.
            </p>
          </div>
        </footer>
    </div>
  );
}

export default Contact;
