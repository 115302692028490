import React, { useEffect } from 'react';
import VanillaTilt from 'vanilla-tilt';
import '../styles/Stats.css';

function Stats() {
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".col .project-card"), {
      max: 30,
      speed: 600,
      scale: 1,
      transition: true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      perspective: 600,
      glare: true,
    });

    VanillaTilt.init(document.querySelectorAll(".chat-list .chat-item"), {
      max: 30,
      speed: 600,
      scale: 1,
      transition: true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      perspective: 600,
      glare: true,
    });
  }, []);

  return (
    <div id='statsBody'>
      <section className='statSection' style={{ marginTop: '40px' }}>
        <main className="mainStats">
          <div className="wrapper">
            <div className="hero">
              <div className="hero-heading">
                <h3>Boosting Businesses</h3>
                <span>Pending | July, 2024</span>
              </div>
              <div className="hero-heading">
                <h3>Statistics <i className="bx bx-chevron-down"></i></h3>
              </div>

              <div className="project">
                <div className="col">
                  <div className="project-card">
                    <div className="card-header">
                      <date>June 1, 2024</date>
                      <span><i className="bx bx-dots-vertical-rounded"></i></span>
                    </div>
                    <div className="card-body">
                      <h6>Year Over Growth</h6>
                      <p>YoY</p>
                      <div className="progress-box">
                        <label htmlFor="progress">Progress</label>
                        <progress id="progress" value="72" max="100">72%</progress>
                        <span>72%</span>
                      </div>
                    </div>
                    <div className="card-footer">
                      <ul className="team">
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                        </svg>
                        </li>
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672Zm-7.518-.267A8.25 8.25 0 1 1 20.25 10.5M8.288 14.212A5.25 5.25 0 1 1 17.25 10.5" />
                        </svg>
                        </li>
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                          <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                        </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="project-card">
                    <div className="card-header">
                      <date>June 1, 2024</date>
                      <span><i className="bx bx-dots-vertical-rounded"></i></span>
                    </div>
                    <div className="card-body">
                      <h6>Client Revenue</h6>
                      <p>Profit</p>
                      <div className="progress-box">
                        <label htmlFor="progress">Improvement</label>
                        <progress id="progress" value="140" max="150">140%</progress>
                        <span>$140k+</span>
                      </div>
                    </div>
                    <div className="card-footer">
                      <ul className="team">
                        <li className="team-member">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                          </svg>
                        </li>
                        <li className="team-member">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                          </svg>
                        </li>
                        <li className="team-member">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="project-card">
                    <div className="card-header">
                      <date>June 1, 2024</date>
                      <span><i className="bx bx-dots-vertical-rounded"></i></span>
                    </div>
                    <div className="card-body">
                      <h6>Customer Retention Rate</h6>
                      <p>Satisfaction + Loyalty</p>
                      <div className="progress-box">
                        <label htmlFor="progress">Improvement</label>
                        <progress id="progress" value="95" max="100">95%</progress>
                        <span>95%</span>
                      </div>
                    </div>
                    <div className="card-footer">
                      <ul className="team">
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
                        </svg>
                        </li>
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                        </svg>
                        </li>
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
                        </svg>
                        </li>
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                        </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="project-card">
                    <div className="card-header">
                      <date>June 1, 2024</date>
                      <span><i className="bx bx-dots-vertical-rounded"></i></span>
                    </div>
                    <div className="card-body">
                      <h6>Net Promoter Score</h6>
                      <p>NPS</p>
                      <div className="progress-box">
                        <label htmlFor="progress">Improvement</label>
                        <progress id="progress" value="50" max="100">50%</progress>
                        <span>+50%</span>
                      </div>
                    </div>
                    <div className="card-footer">
                      <ul className="team">
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" />
                        </svg>
                        </li>
                        <li className="team-member">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        </li>
                        <li className="team-member">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
                        </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="chat">
              <div className="chat-heading">
                <h5>Client Reviews</h5>
                <span><i className="bx bx-search"></i></span>
              </div>
              <div role="list" className="chat-list">
                <div className="chat-item" role="listitem">
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                  </figure>
                  <div>
                    <h6 className="chat-identifier">
                      Johnathan Smith <span><i className="bx bx-star"></i></span>
                    </h6>
                    <p>
                    "Working with this team was a game-changer for me!
                     They understood my vision right from the start and
                      delivered a product that exceeded my expectations.
                       I'm truly impressed with their professionalism and
                        dedication. Highly recommended!"
                    </p>
                    <time>15 January</time>
                  </div>
                </div>
                <div className="chat-item" role="listitem">
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                  </figure>
                  <div>
                    <h6 className="chat-identifier">
                      Emily Johnson <span><i className="bx bx-star"></i></span>
                    </h6>
                    <p>
                    "Absolutely amazing! The service exceeded my expectations.
                     From start to finish, everything was handled with care and
                      attention to detail. I'm thrilled with the results and will 
                      be a returning customer."
                    </p>
                    <time>23 February</time>
                  </div>
                </div>
                <div className="chat-item" role="listitem">
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                  </figure>
                  <div>
                    <h6 className="chat-identifier">
                      Michael Brown <span><i className="bx bx-star"></i></span>
                    </h6>
                    <p>
                    "My experience with this service was fantastic! The team was highly
                     professional and delivered exactly what I needed. I would definitely
                      recommend them to anyone looking for top-notch service."
                    </p>
                    <time>11 March</time>
                  </div>
                </div>
                <div className="chat-item chat-itemHide" role="listitem">
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                  </figure>
                  <div>
                    <h6 className="chat-identifier">
                      Sarah Lee
                    <span><i className="bx bx-star"></i></span>
                    </h6>
                    <p>
                      "Exceptional work! The team quickly grasped my requirements and delivered outstanding results. Highly recommended!"
                    </p>
                    <time>7 April</time>
                  </div>
                </div>
                <div className="chat-item chat-itemHide" role="listitem">
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>   
                 </figure>
                  <div>
                    <h6 className="chat-identifier">
                      David Martinez<span><i className="bx bx-star"></i></span>
                    </h6>
                    <p>
                    "Great service and attention to detail. Very satisfied with the outcome!"
                    </p>
                    <time>20 May</time>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}

export default Stats;