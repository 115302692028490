import React from 'react';
import '../styles/RefundPolicy.css';
import Contact from '../pages/Contact';


function RefundPolicy() {
  return (
    <div>
    <div id='RefundPolicyBody'>
      <div className='RefundPolicyContainer'>
        <div className='LeftSideRefundPolicy'>
            <h1>Refund Policy</h1>
        </div>
        <div className='RightSideRefundPolicy'>
            <div className='firstRefundPolicy'>
                <h1>A Legal Disclaimer</h1>
                <p>
                    Please read this refund policy carefully. By making a purchase, you acknowledge that you have read and agree to the terms of this refund policy.
                </p>
            </div>
            <div className='secondRefundPolicy'>
                <h1>Refund Policy - The Basics</h1>
                <p>
                    Our refund policy is designed to ensure customer satisfaction and clarity in our processes. Refunds are only applicable within the first week of noticing any issues with the service provided. During this period, we will work to fix any problems. However, no actual refunds will be made.
                </p>
            </div>
            <div className='thirdRefundPolicy'>
                <h1>What to Include in the Refund Policy</h1>
                <p>
                    1. A 25% down payment is required before we start creating your website. If you decide to cancel after this payment, you are entitled to a 50% refund of the down payment, but not the full amount.<br/><br/>
                    2. Once the website is fully delivered and all verification steps are completed, and if the full payment has been made, no refunds will be issued.<br/><br/>
                    3. We offer free edits for the first week after delivery. Any additional edits required after this period will be charged based on the complexity of the changes needed.
                </p>
            </div>
        </div>
      </div>
      
    </div><Contact /> </div>
  );
}

export default RefundPolicy;
