import React, { useEffect, useState } from 'react';
import '../styles/About.css';
import 'animate.css';

function About() {
  // Define an array of random code snippets
  const codeSnippets = [
    '<table><thead><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th></tr></thead><tbody><tr><td>Data 1</td><td>Data 2</td><td>Data 3</td></tr><tr><td>Data 4</td><td>Data 5</td><td>Data 6</td></tr></tbody></table><form><label for="name">Name:</label><input type="text" id="name" name="name"><br><label for="email">Email:</label><input type="email" id="email" name="email"><br><button type="submit">Submit</button></form><div>This is a div element</div><p>Paragraph with some text</p><h1>Heading Level 1</h1><a href="#">Link to nowhere</a><span>This is a span element</span><button>Click me!</button><img src="image.jpg" alt="Image description"><ul><li>List item 1</li><li>List item 2</li><li>List item 3</li></ul><table><thead><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th></tr></thead><tbody><tr><td>Data 1</td><td>Data 2</td><td>Data 3</td></tr><tr><td>Data 4</td><td>Data 5</td><td>Data 6</td></tr></tbody></table><form><label for="name">Name:</label><input type="text" id="name" name="name"><br><label for="email">Email:</label><input type="email" id="email" name="email"><br><button type="submit">Submit</button></form>',
    '<div>This is a div element</div>',
    '<p>Paragraph with some text</p>',
    '<h1>Heading Level 1</h1>',
    '<a href="#">Link to nowhere</a>',
    '<span>This is a span element</span>',
    '<button>Click me!</button>',
    '<img src="image.jpg" alt="Image description">',
    '<ul><li>List item 1</li><li>List item 2</li><li>List item 3</li></ul>',
    '<table><thead><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th></tr></thead><tbody><tr><td>Data 1</td><td>Data 2</td><td>Data 3</td></tr><tr><td>Data 4</td><td>Data 5</td><td>Data 6</td></tr></tbody></table>',
    '<form><label for="name">Name:</label><input type="text" id="name" name="name"><br><label for="email">Email:</label><input type="email" id="email" name="email"><br><button type="submit">Submit</button></form>',
    '<div>This is a div element</div>',
    '<p>Paragraph with some text</p>',
    '<h1>Heading Level 1</h1>',
    '<a href="#">Link to nowhere</a>',
    '<span>This is a span element</span>',
    '<button>Click me!</button>',
    '<img src="image.jpg" alt="Image description">',
    '<ul><li>List item 1</li><li>List item 2</li><li>List item 3</li></ul>',
    '<table><thead><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th></tr></thead><tbody><tr><td>Data 1</td><td>Data 2</td><td>Data 3</td></tr><tr><td>Data 4</td><td>Data 5</td><td>Data 6</td></tr></tbody></table><form><label for="name">Name:</label><input type="text" id="name" name="name"><br><label for="email">Email:</label><input type="email" id="email" name="email"><br><button type="submit">Submit</button></form><div>This is a div element</div><p>Paragraph with some text</p><h1>Heading Level 1</h1><a href="#">Link to nowhere</a><span>This is a span element</span><button>Click me!</button><img src="image.jpg" alt="Image description"><ul><li>List item 1</li><li>List item 2</li><li>List item 3</li></ul><table><thead><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th></tr></thead><tbody><tr><td>Data 1</td><td>Data 2</td><td>Data 3</td></tr><tr><td>Data 4</td><td>Data 5</td><td>Data 6</td></tr></tbody></table><form><label for="name">Name:</label><input type="text" id="name" name="name"><br><label for="email">Email:</label><input type="email" id="email" name="email"><br><button type="submit">Submit</button></form>'
  ];

  const [currentSnippetIndex, setCurrentSnippetIndex] = useState(0);
  const [hideNav, setHideNav] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSnippetIndex(index => (index + 1) % codeSnippets.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      setHideNav(currentScrollTop > lastScrollTop);

      const elements = document.querySelectorAll('.animate-on-scroll');
      elements.forEach(element => {
        if (window.scrollY + window.innerHeight > element.offsetTop) {
          element.classList.add('animate');
        }
      });

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.location.href = '/'; // Navigate to homepage if sectionId is not found
    }
  };


  return (
    <div className='peepeepoopoo'>
      {/* Carousel of code snippets */}
      <div className="carousel-container">
        <div className="carousel">
          {codeSnippets.map((snippet, index) => (
            <div key={index} className={`carousel-item ${index === currentSnippetIndex ? 'active' : ''}`}>
              <pre>{snippet}</pre>
            </div>
          ))}
        </div>
      </div>

      {/* About section */}
      <div id='aboutBody' className="animate-on-scroll">
        <div className='aboutContainer'>
          <h1 className='aboutTitle appear'>
            The Journey To<br></br>
            <span className='aboutTitleOrange appear'>Creation</span>
          </h1>
          <p className='aboutText appear'>
            How we rose up to become leaders in the web development industry.
          </p>
          <p className='aboutParagraph appear'>
            Our story began in a small home office with a passion for web development.
            Starting with a handful of clients, our dedication and commitment to quality helped us grow steadily.
            Today, we are a strong team of talented developers, crafting fully customized websites tailored to our clients'
            unique needs. Join us as we continue to push the boundaries of web development and deliver exceptional results.
          </p>
          <a href="/" onClick={(e) => scrollToSection(e, 'servicesBody')} className='aboutButton appear'>
            <button >
              Our Services
            </button>
          </a>
        </div>
      </div>

      {/* Second carousel of code snippets */}
      <div className="carousel-container">
        <div className="carousel">
          {codeSnippets.map((snippet, index) => (
            <div key={index} className={`carousel-item ${index === currentSnippetIndex ? 'active' : ''}`}>
              <pre>{snippet}</pre>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;