import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import Stats from './pages/Stats';
import Industries from './pages/Industries';
import Process from './pages/Process';
import GetStarted from './pages/GetStarted';
import Booking from './pages/Booking';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import ConsultationConfirmation from './pages/ConsultationConfirmation';
import NavMenu from './pages/Nav';
import About from './pages/About';
import MobileBooking from './pages/MobileBooking';
import RefundPolicy from './pages/RefundPolicy';
import FAQ from './pages/FAQ';
import TAC from './pages/TAC';
import PrivacyPolicy from './pages/PrivacyPolicy';


const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <NavMenu /> {/* Include your navigation menu component outside of Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/process" element={<Process />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/consultation-confirmation" element={<ConsultationConfirmation />} />
        <Route path="/mobile-booking" element={<MobileBooking />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/TAC" element={<TAC />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  rootElement
);

reportWebVitals();
