import React from 'react';
import Landing from './Landing';
import About from './About';
import Services from './Services';
import Stats from './Stats';
import Industries from './Industries';
import Process from './Process';
import GetStarted from './GetStarted';
import Booking from './Booking';
import Partners from './Partners';
import Contact from './Contact';

const Home = () => (
  <div>
    <section id="landing">
      <Landing />
    </section>
    <section id="about">
      <About />
    </section>
    <section id="services">
      <Services />
    </section>
    <section id="stats">
      <Stats />
    </section>
    <section id="industries">
      <Industries />
    </section>
    <section id="process">
      <Process />
    </section>
    <section id="getstarted">
      <GetStarted />
    </section>
    <section id="booking">
      <Booking />
    </section>
    <section id="partners">
      <Partners />
    </section>
    <section id="contact">
      <Contact />
    </section>
  </div>
);

export default Home;
