import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/MobileBooking.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase'; // Adjust the path to your firebase.js
import { collection, getDocs } from 'firebase/firestore';
import Contact from '../pages/Contact';

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function MobileBooking() {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [bookedTimes, setBookedTimes] = useState([]); // State for booked times
  const [alertMessage, setAlertMessage] = useState(''); // State for alert message

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchBookedTimes() {
      const querySnapshot = await getDocs(collection(db, 'consultations'));
      const fetchedTimes = querySnapshot.docs.map(doc => doc.data());
      setBookedTimes(fetchedTimes);
    }

    fetchBookedTimes();
  }, []);

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  };

  const formatSelectedDate = () => {
    if (selectedDay) {
      const date = new Date(currentYear, currentMonth - 1, selectedDay);
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    }
    return '';
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const startingDayOfMonth = (month, year) => {
    return new Date(year, month - 1, 1).getDay();
  };

  const moveToPreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const moveToNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const daysCount = daysInMonth(currentMonth, currentYear);
  let startDay = startingDayOfMonth(currentMonth, currentYear);

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setSelectedTime(null); // Reset selected time when day changes
  };

  const isTimeBooked = (day, time) => {
    return bookedTimes.some(booking => booking.selectedDay === day && booking.selectedTime === time);
  };

  const handleTimeClick = (time) => {
    // Check if the time is available (not booked)
    if (!isTimeBooked(selectedDay, time)) {
      setSelectedTime(time);
    } else {
      // Notify the user that the time slot is already booked
      setAlertMessage('This time slot is already booked. Please choose another time.');
    }
  };

  const generateDayButtons = () => {
    let buttons = [];
    if (startDay === 0) startDay = 7;

    for (let i = 0; i < startDay - 1; i++) {
      buttons.push(<button key={`empty${i}`} className='bookingCalendarDayNumberInput disabled' disabled></button>);
    }

    for (let day = 1; day <= daysCount; day++) {
      const date = new Date(currentYear, currentMonth - 1, day);
      const isPast = date < new Date();
      const isSunday = date.getDay() === 0;
      const isSaturday = date.getDay() === 6;
      const isSelected = selectedDay === day;

      let className = 'bookingCalendarDayNumberInput';
      if (isPast) className += ' pastDay';
      if (isSunday || isSaturday) className += ' faded';
      if (isSelected) className += ' selected';

      buttons.push(
        <button
          key={day}
          className={className}
          onClick={() => handleDayClick(day)}
          disabled={isPast || isSunday || isSaturday}
          style={{ height: '40px' }}
        >
          {day}
        </button>
      );
    }

    const remainingButtons = 7 - buttons.length % 7;
    for (let i = 0; i < remainingButtons; i++) {
      buttons.push(<button key={`empty${buttons.length}`} className='bookingCalendarDayNumberInput disabled' disabled></button>);
    }

    return buttons;
  };

  const generateTimeButtons = () => {
    const times = [
      '9:00 am', '10:00 am', '11:00 am', '12:00 pm', '1:00 pm',
      '2:00 pm', '3:00 pm', '4:00 pm', '5:00 pm', '6:00 pm', '7:00 pm'
    ];

    return times.map((time) => {
      const isBooked = isTimeBooked(selectedDay, time);

      return (
        <button
          key={time}
          className={`bookingSelectableButton ${selectedTime === time ? 'selected' : ''} ${isBooked ? 'disabled faded' : ''}`}
          onClick={() => handleTimeClick(time)}
          disabled={isBooked}
        >
          {time}
        </button>
      );
    });
  };

  const handleNextClick = () => {
    if (!selectedDay || !selectedTime) {
      setAlertMessage('Please select date and time to continue');
    } else {
      const formattedDate = `${getMonthName(currentMonth)} ${selectedDay}, ${currentYear}`;
      navigate('/consultation-confirmation', { state: { selectedDay, selectedTime, formattedDate } });

      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
  };

  return (
    <div id='mobileBookingBody'>
      {alertMessage && (
        <div className="MbookingAlert">
          <span>{alertMessage}</span>
          <button className="MbookingcloseButton" onClick={handleCloseAlert}>&times;</button>
        </div>
      )}
      <div id='bookingMobileConfirmationBody'>
          <div className='bookingMobileBackButton'>
            <a href='/'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='size-5 bookinBackSVG'
              >
                <path d='M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3' />
              </svg>
              Back
            </a>
          </div>
        </div>
        <div id='bookingBodyMobile'>
      <div className='bookingLRContainerMobile'>
        <div className='bookingMainContainerMobile'>
          <div className='bookingtitleContainerMobile'>
          <div className='bookingTitleMainMobile'>
            <h1>
              Pick Your Spot
            </h1>
          </div>
          <div className='bookingTitleTextMainMobile'>
            <p>
              Timezone: Eastern Daylight Time (EDT)
            </p>
          </div>
          </div>
          <div className='bothsidesBookingContainerMobile'>
          <div className='leftSideBookingSystemMobile'>
            <div className='calendarBookingSystemMobile'>
              <div className='calendarMonthYearMobile'>
                <div className='calendarLeftMobile'>
                  <button onClick={moveToPreviousMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0732EF" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                  </button>
                </div>
                <div className='calendarMonthYearDisplayMobile'>
                  <h1>
                    {`${getMonthName(currentMonth)}  ${currentYear}`}
                  </h1>
                </div>
                <div className='calendarRightMobile'>
                  <button onClick={moveToNextMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0732EF" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className='bookingCalendarDisplayMobile'>
                <div className='bookingDayColumnMobile'>
                  <div className='bookingCalendarDayMobile'>
                    <p>{dayNames[0]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 6)}
                </div>
                <div className='bookingDayColumnMobile'>
                  <div className='bookingCalendarDayMobile'>
                    <p>{dayNames[1]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 0)}
                </div>
                <div className='bookingDayColumnMobile'>
                  <div className='bookingCalendarDayMobile'>
                    <p>{dayNames[2]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 1)}
                </div>
                <div className='bookingDayColumnMobile'>
                  <div className='bookingCalendarDayMobile'>
                    <p>{dayNames[3]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 2)}
                </div>
                <div className='bookingDayColumnMobile'>
                  <div className='bookingCalendarDayMobile'>
                    <p>{dayNames[4]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 3)}
                </div>
                <div className='bookingDayColumnMobile'>
                  <div className='bookingCalendarDayMobile'>
                    <p>{dayNames[5]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 4)}
                </div>
                <div className='bookingDayColumnMobile'>
                  <div className='bookingCalendarDayMobile'>
                    <p>{dayNames[6]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 5)}
                </div>
              </div>
            </div>
          </div>
          <div className='rightSideBookingSystemMobile'>
            <div className='timeBookingSystemMobile'>
              <div className='timeSelectionContainerMobile'>
                <h1 className='timeSelectionTextMobile'>
                  {selectedDay ? formatSelectedDate() : "Pick a Date"}
                </h1>
              </div>
              <div className='timeButtonsContainerMobile'>
                {generateTimeButtons()}
              </div>
            </div>
          </div>
          <div className='rdSideofBookingSystemMobile'>
                <div className='serviceDetailsTitleMobile'>
                  <h1>
                    Service Details
                  </h1>
                </div>
                <div className='serviceDetailsTextMobile'>
                  <p>
                    Free Consultation
                  </p>
                </div>
                <div className='serviceDetailsDateDisplayMobile'>
                  <p>
                    {selectedDay && formatSelectedDate()} at {selectedTime}
                  </p>
                </div>
                <div className='serviceDetailsMeetMethodMobile'>
                  <p>
                    Zoom Meeting
                  </p>
                </div>  
                <div className='serviceDetailsRepMobile'>
                  <p>Team Member</p>
                </div>
                <div className='serviceDetailsDurationMobile'>
                  <p>
                    1 hr
                  </p>
                </div>
                  <div className='serviceDetailsNextButtonMobile'>
                    <button className='bookingNextButtonMobile' onClick={handleNextClick}>
                    <div className='bookingNextButtonTextMobile'>
                      <p>Next</p>
                    </div>
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <Contact />
    </div>
  );
}

export default MobileBooking;
