import React from 'react';
import '../styles/GetStarted.css';

function GetStarted() {
  return (
    <div id='getStartedBody'>
      <div className='content appear'>
        <h1>Get Started</h1>
        <p>Book now to get a meeting with one of our experts to envision your site.</p>
      </div>
    </div>
  );
}

export default GetStarted;
