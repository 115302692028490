import React, { useState, useEffect } from 'react';
import '../styles/Nav.css';
import logo from '../otherIMG/logo.png';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import Framer Motion

function NavMenu() {
  const [hideNav, setHideNav] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop > lastScrollTop) {
        setHideNav(true); // Scrolling down
      } else {
        setHideNav(false); // Scrolling up
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      toggleMobileMenu(); // Close mobile menu after scroll
    } else {
      window.location.href = '/'; // Navigate to homepage if sectionId is not found
    }
  };

  const toggleMobileMenu = () => {
    const x = document.getElementById('myLinks');
    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  };

  return (
    <div>
      {/* Desktop Navigation */}
      <header className="header">
        <nav className="nav">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </div>

          <ul className="nav-list">
            {/* Using motion.li for animated list items */}
            <motion.li className="nav-item" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }}>
              <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'aboutBody')}>
                About
              </a>
            </motion.li>
            <motion.li className="nav-item" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }}>
              <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'servicesBody')}>
                Services
              </a>
            </motion.li>
            <motion.li className="nav-item active" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }}>
              <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'ProcessBody')}>
                Process
              </a>
            </motion.li>
            <motion.li className="nav-item" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }}>
              <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'getStartedBody')}>
                Lets Talk
              </a>
            </motion.li>
          </ul>
          <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'contactBody')}>
            <motion.button className="btn-action" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              Contact
            </motion.button>
            
          </a>
        </nav>
      </header>

      {/* Mobile Navigation */}
      <div className="mobileNav">
        <div className="topnav">
          <a href="/" className="active mobileNavImg">
            <img src={logo} alt="Logo" />
          </a>
          <motion.div
            id="myLinks"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, staggerChildren: 0.2 }}
          >
            <motion.a
              href="/"
              onClick={(e) => {
                toggleMobileMenu();
                scrollToSection(e, 'aboutBody');
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              About Us
            </motion.a>
            <motion.a
              href="/"
              onClick={(e) => {
                toggleMobileMenu();
                scrollToSection(e, 'scrollBody');
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Our Services
            </motion.a>
            <motion.a
              href="/"
              onClick={(e) => {
                toggleMobileMenu();
                scrollToSection(e, 'scrollMobileContact');
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Let's Talk
            </motion.a>
          </motion.div>
          <a href="javascript:void(0);" className="icon" onClick={toggleMobileMenu}>
            <i className="fa fa-bars">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#E87551" className="size-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavMenu;
