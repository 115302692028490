import React from 'react';
import '../styles/FAQ.css';
import Contact from '../pages/Contact';


function FAQ() {
  return (
    <div>
    <div id='FAQBody'>
      <div className='FAQContainer'>
        <div className='FAQHeader'>
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className='FAQContent'>
          <div className='FAQSection'>
            <h2>What services do you offer?</h2>
            <p>
              We offer comprehensive website development services, building sites from scratch with code. Our services include website design, development, and customization. Additionally, we assist in publishing your site on various platforms like Vercel, Hostinger, Heroku, and Firebase.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>Do you use AI to build websites?</h2>
            <p>
              No, we do not use AI to build websites. Our team of skilled developers creates each website from scratch, ensuring a unique and high-quality product tailored to your specific needs.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>How long does it take to develop a website?</h2>
            <p>
              Our typical website development process, from start to finish, takes about a week. This includes the design, development, and deployment stages.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>Can you customize my website?</h2>
            <p>
              Absolutely! We can customize every aspect of your website to meet your specific requirements. From design elements to functionality, we ensure that your website reflects your brand and meets your business goals.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>What platforms can you publish my website on?</h2>
            <p>
              We can help publish your website on various platforms, including Vercel, Hostinger, Heroku, and Firebase. If you have a preferred platform, let us know, and we’ll work to get your site live there.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>What is your refund policy?</h2>
            <p>
              Our refund policy is valid within the first week of service. If any issues are noticed, we will work to fix them; however, no actual refunds will be made. A 25% down payment is required before we begin creating the website. If you decide to cancel after this payment, you can receive half of the down payment back, but not the full amount. Once the website is fully delivered and all verification steps are completed, and payment is made in full, no refunds will be issued. Free edits are available for the first week, after which edit prices will be determined based on difficulty.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>How do I get started?</h2>
            <p>
              Getting started is easy! Contact us with your project details and requirements, and our team will reach out to discuss your needs and begin the development process.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>Do you offer maintenance services?</h2>
            <p>
              Yes, we offer maintenance services to ensure your website remains up-to-date and runs smoothly. Contact us for more details on our maintenance packages.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>Can you help with SEO?</h2>
            <p>
              Yes, we can integrate SEO best practices into your website development to help improve your site’s visibility on search engines. We focus on creating SEO-friendly code and structure to enhance your site’s performance.
            </p>
          </div>
          <div className='FAQSection'>
            <h2>What if I need changes after the website is live?</h2>
            <p>
              We offer free edits for the first week after the website is delivered. After that, any additional changes will be priced based on their complexity. Our team is always here to help with any updates or modifications you need.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Contact />
    </div>
  );
}

export default FAQ;
