import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import '../styles/Landing.css';
import '../styles/Nav.css';
import '@lottiefiles/lottie-player';
import Helmet from 'react-helmet';

function Landing() {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1 },
    });
  }, [controls]);

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.location.href = '/';
    }
  };

  return (
    <div id='landingBody'>
      <Helmet>
        <title>Your Business Growth</title>
        <meta name="description" content="We help you to grow your business with a professional website. More clients, more growth." />
        <meta name="keywords" content="business, growth, website, professional, clients" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="landingMain">
        <div className="home-container">
          <motion.section
            className="hero"
            initial={{ opacity: 0, y: 30 }}
            animate={controls}
            aria-labelledby="hero-title"
          >
            <h1 id="hero-title" className="hero-title">
              <span className="highlight">We</span> help you <br /> to grow your Business.
            </h1>
            <h2 className="hero-desp">
              Our ultimate motto is with a professional website <br /> comes more clients.
            </h2>
            <div className="btn-group">
              <a className="start-btn" href="/" onClick={(e) => scrollToSection(e, 'aboutBody')}>
                Get Started
              </a>
            </div>
          </motion.section>
          <section className="hero-detail">
            <span className="bg aurora" style={{ '--i': 0 }}></span>
            <span className="bg aurora" style={{ '--i': 1 }}></span>
            <span className="bg aurora" style={{ '--i': 2 }}></span>
            <span className="bg aurora" style={{ '--i': 3 }}></span>

            <div className="player">
              <lottie-player
                src="Animation.json"
                background="transparent"
                speed="1"
                loop
                autoplay
                aria-label="Business growth animation"
              ></lottie-player>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

export default Landing;
