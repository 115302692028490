import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../firebase'; // Adjust import path based on your project structure
import emailjs from 'emailjs-com'; // Import emailjs-com library
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import Contact from '../pages/Contact';
import '../styles/ConsultationConfirmation.css'; // Import your CSS file

function ConsultationConfirmation() {
  const location = useLocation();
  const { selectedDay, selectedTime, formattedDate } = location.state || {};
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [alertMessage, setAlertMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { name, email, phoneNumber, message } = formData;

    try {
      // Check if required fields are filled
      if (!name || !email) {
        throw new Error('Name and email are required.');
      }

      // Store consultation data in Firestore
      const docRef = await addDoc(collection(db, 'consultations'), {
        name,
        email,
        phoneNumber,
        message,
        selectedDay,
        selectedTime,
        formattedDate,
        timestamp: serverTimestamp(),
      });

      // Clear form fields on successful submission
      setFormData({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
      });

      // Send email notification via Formspree
      const responseFormspree = await fetch(process.env.REACT_APP_FORMSPREE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          message,
          selectedDay,
          selectedTime,
          formattedDate,
        }),
      });

      if (!responseFormspree.ok) {
        throw new Error('Failed to send email notification via Formspree.');
      }

      // Send email using EmailJS
      const templateParams = {
        name,
        email,
        message,
        selectedDay,
        selectedTime,
        formattedDate,
      };

      const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
      const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
      const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;

      await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, EMAILJS_USER_ID);

      setAlertMessage('Thank you for booking a consultaion!'); // Show success alert

    } catch (error) {
      console.error('Error submitting form:', error.message);
      setAlertMessage('Failed to book consultation. Please try again later.'); // Show error alert
    }
  };

  return (
    <div>
      <div id='consultationConfirmationBody'>
        {/* Alert */}
        {alertMessage && (
          <div className={`alertConfimation ${alertMessage.includes('Thank you') ? 'alert-successConfirmation' : 'alert-errorConfirmation'}`}>
            {alertMessage}
            <a href='/'>
            <button className='HomeConfirmation' onClick={() => setAlertMessage('')}>Go Home</button>
            </a>
          </div>
        )}

        <form onSubmit={handleSubmit} className='ConfirmFormConsultation'>
          <div className='CCFBackButton'>
            <a href='/'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='size-5 bookinBackSVG'
              >
                <path d='M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3' />
              </svg>
              Back
            </a>
          </div>
          <div className='mainCCFcontainer'>
            <div className='2ndCCFcontainer'>
              <div className='CCFtitle'>
                <h1>Client Details</h1>
              </div>
              <div className='CCFtext'>
                <p>Tell us a bit about yourself</p>
              </div>
              <div className='CCF2sides'>
                <div className='CCFLeftSide'>
                  <div className='CCFNameInput'>
                    <p>Name*</p>
                    <input
                      type='text'
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='CCFEmailInput'>
                    <p>Email*</p>
                    <input
                      type='email'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='CCFNumberInput'>
                    <p>Phone Number</p>
                    <input
                      type='tel'
                      name='phoneNumber'
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='CCFTextArea'>
                    <p>Add Your Message</p>
                    <textarea
                      rows='4'
                      name='message'
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className='CCFRightSide'>
                  <div className='CCFBookingTitle'>
                    <p>Booking Details</p>
                  </div>
                  <div className='CCFBookingDetail'>
                    <p>Free Consultation</p>
                  </div>
                  <div className='CCFDayNTime'>
                    <p>
                      {formattedDate} at {selectedTime}
                    </p>
                  </div>
                  <div className='CCFDetailWhere'>
                    <p>Zoom Meeting</p>
                  </div>
                  <div className='CCFWho'>
                    <p>Team Member</p>
                  </div>
                  <div className='CCFBookingDuration'>1 hr</div>
                  <button type='submit' className='CCFBookButton'>
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Contact />
    </div>
  );
}

export default ConsultationConfirmation;

