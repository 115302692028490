import '../styles/Services.css';



function Services() {
  return (
    <div id='servicesBody'>
      <div className='servicesContainer'>
        <div className='leftSideContainer appear'>
          <h1>
            <span className='leftSideSpanOrange'>Our</span> Services
          </h1>
          <p className='appear'>
            We work hard to turn your vision into reality.
            Our expert team ensures your website looks professional
            and functions seamlessly. A better website means more customers
            and growth for your business.
          </p>
        </div>
        <div className="grid auto-rows-[192px] grid-cols-3 gap-4 mobileGrid appear">
            {[
                { title: "Web Design", text: "Craft stunning websites that blend creativity with seamless user experience, ensuring your brand stands out." },
                { title: "Web Development", text: "Build robust, scalable websites and web applications tailored to your business objectives." },
                { title: "SEO Strategy", text: "Enhance online visibility and drive organic traffic with effective SEO strategies and keyword optimization." },
                { title: "Marketing Assistance", text: "Navigate digital marketing complexities with compelling campaigns and expert social media management." },
                { title: "Conversion Rate Optimization", text: "Maximize website potential with CRO services that optimize user experience and content." }
            ].map((service, i) => (
                <div
                key={i}
                className={`row-span-1 rounded-xl border-2 border-slate-400/10 bentoBoxes ${
                    i === 3 || i === 6 ? "col-span-2" : ""
                }`}
                >
                <h2 className="text-xl font-bold mb-2">{service.title}</h2>
                <p className="text-sm">{service.text}</p>
                </div>
            ))}
            </div>
      </div>
    </div>
  );
}

export default Services;