import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/Booking.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase'; // Adjust the path to your firebase.js
import { collection, getDocs } from 'firebase/firestore';

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function Booking() {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [bookedTimes, setBookedTimes] = useState([]); // State for booked times

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchBookedTimes() {
      const querySnapshot = await getDocs(collection(db, 'consultations'));
      const fetchedTimes = querySnapshot.docs.map(doc => doc.data());
      setBookedTimes(fetchedTimes);
    }

    fetchBookedTimes();
  }, []);

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  };

  const formatSelectedDate = () => {
    if (selectedDay) {
      const date = new Date(currentYear, currentMonth - 1, selectedDay);
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    }
    return '';
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const startingDayOfMonth = (month, year) => {
    return new Date(year, month - 1, 1).getDay();
  };

  const moveToPreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const moveToNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const daysCount = daysInMonth(currentMonth, currentYear);
  let startDay = startingDayOfMonth(currentMonth, currentYear);

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setSelectedTime(null); // Reset selected time when day changes
  };

  const isTimeBooked = (day, time) => {
    return bookedTimes.some(booking => booking.selectedDay === day && booking.selectedTime === time);
  };

  const handleTimeClick = (time) => {
    // Check if the time is available (not booked)
    if (!isTimeBooked(selectedDay, time)) {
      setSelectedTime(time);
    } else {
      // Notify the user that the time slot is already booked
      setAlertMessage('This time slot is already booked. Please choose another time.');
    }
  };

  const generateDayButtons = () => {
    let buttons = [];
    if (startDay === 0) startDay = 7;

    for (let i = 0; i < startDay - 1; i++) {
      buttons.push(<button key={`empty${i}`} className='bookingCalendarDayNumberInput disabled' disabled></button>);
    }

    for (let day = 1; day <= daysCount; day++) {
      const date = new Date(currentYear, currentMonth - 1, day);
      const isPast = date < new Date();
      const isSunday = date.getDay() === 0;
      const isSaturday = date.getDay() === 6;
      const isSelected = selectedDay === day;

      let className = 'bookingCalendarDayNumberInput';
      if (isPast) className += ' pastDay';
      if (isSunday || isSaturday) className += ' faded';
      if (isSelected) className += ' selected';

      buttons.push(
        <button
          key={day}
          className={className}
          onClick={() => handleDayClick(day)}
          disabled={isPast || isSunday || isSaturday}
          style={{ height: '40px' }}
        >
          {day}
        </button>
      );
    }

    const remainingButtons = 7 - buttons.length % 7;
    for (let i = 0; i < remainingButtons; i++) {
      buttons.push(<button key={`empty${buttons.length}`} className='bookingCalendarDayNumberInput disabled' disabled></button>);
    }

    return buttons;
  };

  const generateTimeButtons = () => {
    const times = [
      '9:00 am', '10:00 am', '11:00 am', '12:00 pm', '1:00 pm',
      '2:00 pm', '3:00 pm', '4:00 pm', '5:00 pm', '6:00 pm', '7:00 pm'
    ];

    return times.map((time) => {
      const isBooked = isTimeBooked(selectedDay, time);

      return (
        <button
          key={time}
          className={`bookingSelectableButton ${selectedTime === time ? 'selected' : ''} ${isBooked ? 'disabled faded' : ''}`}
          onClick={() => handleTimeClick(time)}
          disabled={isBooked}
        >
          {time}
        </button>
      );
    });
  };

  const handleNextClick = () => {
    if (!selectedDay || !selectedTime) {
      setAlertMessage('Please select date and time to continue');
    } else {
      const formattedDate = `${getMonthName(currentMonth)} ${selectedDay}, ${currentYear}`;
      navigate('/consultation-confirmation', { state: { selectedDay, selectedTime, formattedDate } });

      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }
  };
  const handleCloseAlert = () => {
    setAlertMessage('');
  };
  
  const [alertMessage, setAlertMessage] = useState('');

  return (
    <div id='bookingBody'>
      {alertMessage && (
        <div className="bookingAlert">
          <span>{alertMessage}</span>
          <button className="bookingcloseButton" onClick={handleCloseAlert}>&times;</button>
        </div>
      )}
      <div className='mobileBookingButton'>
        <a href='/mobile-booking'>
          Book Free Consultation Here!
        </a>
      </div>
      <div className='bookingLRContainer'>
        <div className='bookingMainContainer'>
          <div className='bookingtitleContainer'>
          <div className='bookingTitleMain'>
            <h1>
              Pick Your Spot
            </h1>
          </div>
          <div className='bookingTitleTextMain'>
            <p>
              Timezone: Eastern Daylight Time (EDT)
            </p>
          </div>
          </div>
          <div className='bothsidesBookingContainer'>
          <div className='leftSideBookingSystem'>
            <div className='calendarBookingSystem'>
              <div className='calendarMonthYear'>
                <div className='calendarLeft'>
                  <button onClick={moveToPreviousMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#E87551" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                  </button>
                </div>
                <div className='calendarMonthYearDisplay'>
                  <h1>
                    {`${getMonthName(currentMonth)}  ${currentYear}`}
                  </h1>
                </div>
                <div className='calendarRight'>
                  <button onClick={moveToNextMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#E87551" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className='bookingCalendarDisplay'>
                <div className='bookingDayColumn'>
                  <div className='bookingCalendarDay'>
                    <p>{dayNames[0]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 6)}
                </div>
                <div className='bookingDayColumn'>
                  <div className='bookingCalendarDay'>
                    <p>{dayNames[1]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 0)}
                </div>
                <div className='bookingDayColumn'>
                  <div className='bookingCalendarDay'>
                    <p>{dayNames[2]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 1)}
                </div>
                <div className='bookingDayColumn'>
                  <div className='bookingCalendarDay'>
                    <p>{dayNames[3]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 2)}
                </div>
                <div className='bookingDayColumn'>
                  <div className='bookingCalendarDay'>
                    <p>{dayNames[4]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 3)}
                </div>
                <div className='bookingDayColumn'>
                  <div className='bookingCalendarDay'>
                    <p>{dayNames[5]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 4)}
                </div>
                <div className='bookingDayColumn'>
                  <div className='bookingCalendarDay'>
                    <p>{dayNames[6]}</p>
                  </div>
                  {generateDayButtons().filter((_, idx) => idx % 7 === 5)}
                </div>
              </div>
            </div>
          </div>
          <div className='rightSideBookingSystem'>
            <div className='timeBookingSystem'>
              <div className='timeSelectionContainer'>
                <h1 className='timeSelectionText'>
                  {selectedDay ? formatSelectedDate() : "Pick a Date"}
                </h1>
              </div>
              <div className='timeButtonsContainer'>
                {generateTimeButtons()}
              </div>
            </div>
          </div>
          <div className='rdSideofBookingSystem'>
                <div className='serviceDetailsTitle'>
                  <h1>
                    Service Details
                  </h1>
                </div>
                <div className='serviceDetailsText'>
                  <p>
                    Free Consultation
                  </p>
                </div>
                <div className='serviceDetailsDateDisplay'>
                  <p>
                    {selectedDay && formatSelectedDate()} at {selectedTime}
                  </p>
                </div>
                <div className='serviceDetailsMeetMethod'>
                  <p>
                    Zoom Meeting
                  </p>
                </div>  
                <div className='serviceDetailsRep'>
                  <p>Team Member</p>
                </div>
                <div className='serviceDetailsDuration'>
                  <p>
                    1 hr
                  </p>
                </div>
                  <div className='serviceDetailsNextButton'>
                    <button className='bookingNextButton' onClick={handleNextClick}>
                    <div className='bookingNextButtonText'>
                      <p>Next</p>
                    </div>
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Booking;
