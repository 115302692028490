import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { IoIosBuild } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { MdDesignServices } from "react-icons/md";
import { TfiWorld } from "react-icons/tfi";
import "../styles/Process.css";

const experiencesData = [
  {
    title: "Web Design",
    description:
      "We create stunning, user-friendly designs that capture your brand's essence and engage your audience effectively.",
    icon: <MdDesignServices />,
  },
  {
    title: "Web Development",
    description:
      "Our team builds robust, scalable websites optimized for performance, ensuring a seamless user experience.",
    icon: <IoIosBuild />,
  },
  {
    title: "Delivery + SEO",
    description:
      "We implement effective SEO strategies to enhance your online visibility and drive targeted traffic to your site.",
    icon: <TbTruckDelivery />,
  },
  {
    title: "Marketing",
    description:
      "Our marketing services help you reach your target audience and convert leads into loyal customers.",
    icon: <TfiWorld />,
  },
];

function Process() {

  return (
    <div id='ProcessBody'>
      <section id="experience" className="scroll-mt-28 mb-28 sm:mb-40">
        <h1>Our <span>Process</span></h1>
        <VerticalTimeline lineColor="linear-gradient(to bottom, white 0%, #E87551 100%)">
          {experiencesData.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              contentStyle={{
                boxShadow: "none",
                border: "none",
                borderRadius:"20px",
                textAlign: "left",
                padding: "1.3rem 2rem",
                backgroundColor:"#F0F3FE",
                color:"black",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)"
              }}
              contentArrowStyle={{
                borderRight: "0.4rem solid #E87551",
              }}
              date={item.date}
              icon={item.icon}
              iconStyle={{
                fontSize: "1.5rem",
                color:"black",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", // Box shadow for the circle
              }}
            >
              <h3 className="font-semibold capitalize dark:text-black/75">{item.title}</h3>
              <p className="font-normal !mt-0 dark:text-black/75">{item.location}</p>
              <p className="!mt-1 !font-normal text-gray-700 dark:text-black/75">
                {item.description}
              </p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </section>
    </div>
  );
}

export default Process;